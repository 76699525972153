.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.hero {
  min-height: 100vh;
}
.flex-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-0 {
  flex: 0;
}
.flex-1 {
  flex: 1;
}
.flex-wrap.vertical {
  flex-direction: column;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Great Vibes", cursive;
  font-weight: 100;
  color: rgba(155, 0, 155, 1);
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.5rem;
}
.sans {
  font-family: "Raleway", helvetica, sans-serif;
}
a[href] {
  text-decoration: inherit;
  color: inherit;
  font-size: inherit;
  margin-bottom: -2rem;
  position: relative;
}
a[href]:after {
  position: absolute;
  content: "";
  display: block;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgba(155, 0, 155, 1);
}
div.hearts {
  background: url(https://saralacombe.com/img/heart.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
div.hearts * {
  color: black !important;
}
.center {
  text-align: center;
}
.bold {
  --font-weight: 800;
}
.container.narrow {
  margin: 0 auto;
  width: 90vw;
  max-width: 1000px;
}
.lovely {
  font-family: "Great Vibes", cursive;
}

.pad-m {
  padding: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.regal {
  font-family: Cinzel, serif;
}

@media screen and (max-width: 400px) {
  html {
    font-size: 12px;
  }
}
